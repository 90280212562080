<template>
  <section id="pricing-plan">
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">Tipi di licenza</h1>
      <p class="mb-2 pb-75">Scegli il tipo di licenza più adatto alle tue esigenze.</p>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card" v-if="pricing">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <b-row class="justify-content-center align-items-stretch">
          <b-col md="4" class="d-flex flex-column justify-content-stretch">
            <b-card align="center">
              <h3>A Consumo</h3>
              
              <div class="d-flex flex-column">
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <div v-if="discount(pricing[3])" class="pricing-basic-value text-strikethrough text-primary">€ {{ priceFormatter.format(price(pricing[3])) }}</div>
                    <div v-else class="pricing-basic-value text-primary">&nbsp;</div>
                    <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ priceFormatter.format(finalPrice(pricing[3])) }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/progetto</sub>
                  </div>
                </div>
                <ul class="text-left mb-3 flex-grow-1">
                  <li>Paghi per ogni progetto realizzato</li>
                </ul>
                <b-button @click="$router.push('/cart?type=4')" variant="primary">Seleziona</b-button>
              </div>
            </b-card>
          </b-col>

          <b-col md="4" class="d-flex flex-column justify-content-stretch">
            <b-card align="center">
              <h3>Mensile</h3>
              <div class="d-flex flex-column">
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <div v-if="discount(pricing[1])" class="pricing-basic-value text-strikethrough text-primary">€ {{ priceFormatter.format(price(pricing[1])) }}</div>
                    <div v-else class="pricing-basic-value text-primary">&nbsp;</div>
                    <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ priceFormatter.format(finalPrice(pricing[1])) }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mese</sub>
                    <div class="mt-2"><small>{{ priceFormatter.format(finalPrice(pricing[1]) * 12)}}/anno</small></div>

                  </div>
                </div>
                <ul class="text-left mb-3 flex-grow-1">
                  <li>Progetti Nuovi Impianti: {{ newProjectCredit(pricing[1]) > 200 ? 'Illimitati' : newProjectCredit(pricing[1])}}</li>
                  <li>Progetti Manutenzione Impianti: {{ newProjectCredit(pricing[1]) > 200 ? 'Illimitati' : newProjectCredit(pricing[1])}}</li>
                  <li>Durata abbonameno: 1 Mese</li>
                </ul>
                <b-button @click="$router.push('/cart?type=2')" variant="primary">Seleziona</b-button>
              </div>


            </b-card>
          </b-col>

          <b-col md="4" class="d-flex flex-column justify-content-stretch">
            <b-card align="center">
              <h3>Annuale</h3>
              <div class="d-flex flex-column">
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <div v-if="discount(pricing[2])" class="pricing-basic-value text-strikethrough text-primary">€ {{ priceFormatter.format(price(pricing[2])) }}</div>
                    <div v-else class="pricing-basic-value text-primary">&nbsp;</div>
                    <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ priceFormatter.format(finalPrice(pricing[2]) / 12) }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold mb-2">/mese</sub>
                    <div class="mt-2"><small>{{ priceFormatter.format(finalPrice(pricing[2]))}}/anno</small></div>
                  </div>
                </div>
                <ul class="text-left mb-3">
                  <li>Progetti Nuovi Impianti: {{ newProjectCredit(pricing[2]) > 200 ? 'Illimitati' : newProjectCredit(pricing[2])}}</li>
                  <li>Progetti Manutenzione Impianti: {{ newProjectCredit(pricing[2]) > 200 ? 'Illimitati' : newProjectCredit(pricing[2])}}</li>
                  <li>Durata abbonameno: 12 Mesi</li>
                </ul>
                <b-button @click="$router.push('/cart?type=3')" variant="primary">Seleziona</b-button>
              </div>

            </b-card>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->
  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import { Requests } from '@/api/requests'
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      pricing: null,
      priceFormatter: new Intl.NumberFormat('it-IT', {maximumFractionDigits: 2, minimumFractionDigits: 2}),
    }
  },

  computed: {
    userData: function () {
      return JSON.parse(localStorage.getItem("user"));
    },    
  },

  created() {
    this.getPricing();
    
  },

  methods: {
    price: function(plan) {
      return Number(plan["price_type_" + this.userData.account_type_code]);
    },
    discount: function(plan) {
      return Number(plan["discount_type_" + this.userData.account_type_code]);
    },
    finalPrice: function(plan) {
      return this.price(plan) * (100 - this.discount(plan)) / 100;
    },
    newProjectCredit: function(plan) {
      return plan["project_limit_type_newimplant_" + this.userData.account_type_code];
    },
    maintenanceProjectCredits: function(plan) {
      return plan["project_limit_type_mantenance_" + this.userData.account_type_code];
    },
    async getPricing() {
      const response = await Requests.getLicences();
      this.pricing = response.data;
    }
  },

}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";

.card {
  flex: 1;
}

.text-strikethrough {
  font-size: 20px !important;
  text-decoration: line-through;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card-body div.d-flex {
  flex: 1;
}
</style>
